// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/spinner.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/spinner.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { useNavigation } from '@remix-run/react';
import React from 'react';
export function PLSpinner() {
  _s();
  const {
    state
  } = useNavigation();
  return <div className={"rounded-full h-7 w-7 border-t-2 border-b-2 dark:border-green-400 border-green-400" + (state === 'loading' ? ' animate-spin' : ' hidden')}></div>;
}
_s(PLSpinner, "0tqW62gTrVFSOjfTBcInx1ofqB0=", false, function () {
  return [useNavigation];
});
_c = PLSpinner;
;
var _c;
$RefreshReg$(_c, "PLSpinner");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;