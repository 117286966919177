// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/modals/manager/make-request.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/modals/manager/make-request.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { useState, useRef, useEffect } from 'react';
import { PLBaseModal } from '../base';
import { BarLoader, BeatLoader } from 'react-spinners';
function AudioRecorder({
  open,
  setOpen
}) {
  _s();
  const [isRecording, setIsRecording] = useState(false);
  const [requestMade, setRequestMade] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [volumes, setVolumes] = useState([]);
  const [avgVolume, setAvgVolume] = useState(0);
  const [blob, setBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [action, setAction] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const animationFrameIdRef = useRef(null);
  const [queue, setQueue] = useState([]);
  // const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioRef, setAudioRef] = useState(null);
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true
    });

    // Set up MediaRecorder
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = event => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(audioChunksRef.current, {
        type: 'audio/wav'
      });
      const url = URL.createObjectURL(blob);
      setAudioURL(url);
      setBlob(blob);
      if (open) {
        await sendAudioFileToServer(blob, url);
      } else {
        console.log('canceled request');
      }
      audioChunksRef.current = [];
    };

    // Set up Web Audio API for volume feedback
    audioContextRef.current = new (window.AudioContext || window.AudioContext)();
    analyserRef.current = audioContextRef.current.createAnalyser();
    sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
    sourceRef.current.connect(analyserRef.current);
    analyserRef.current.fftSize = 256;
    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    let silenceTimeoutId = null;
    const SILENCE_DURATION = 1500; // 3 seconds

    // Function to handle detected silence
    const handleSilence = () => {
      if (silenceTimeoutId) {
        clearTimeout(silenceTimeoutId);
      }
      silenceTimeoutId = setTimeout(async () => {
        stopRecording(); // Stop recording if silence detected
      }, SILENCE_DURATION);
    };

    // Function to update volume level and check for silence
    const updateVolume = () => {
      if (analyserRef.current) {
        analyserRef.current.getByteFrequencyData(dataArray);
        const sum = dataArray.reduce((a, b) => a + b, 0);
        const average = sum / dataArray.length;
        const frequencies = Array.from(dataArray).slice(0, 16);
        setVolumes(frequencies);
        setAvgVolume(average);
        if (average > 30) {
          handleSilence(); // Reset silence timeout if audio is detected
        }
        animationFrameIdRef.current = requestAnimationFrame(updateVolume);
      }
    };
    updateVolume();
    mediaRecorderRef.current.start();
    setIsRecording(true);
    setRequestMade(true);
  };
  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
    audioContextRef.current?.close();
    if (animationFrameIdRef.current) {
      cancelAnimationFrame(animationFrameIdRef.current);
    }
    if (audioURL && !requestMade) {
      setRequestMade(true);
    }
  };
  async function sendAudioFileToServer(blob, audioURL) {
    if (!blob || !audioURL || !audioURL?.length || audioURL === 'blob:' || blob?.size === 181925) {
      const transcript = 'Hmm, something went wrong. Please try again later.';
      setAction('speaking');
      await getVoiceFromText(transcript);
      return;
    }
    setAction('processing');
    // This is the initial loading audio. "sure give me a few seconds"
    setAudioUrl('https://storage.googleapis.com/productlamb_project_images/1725916528777-14-speech-1725916528776.mp3');

    // Upload audio and get transcript
    const file = new File([blob], 'audio.wav', {
      type: 'audio/wav'
    });
    // Upload audio and get transcript using fetch
    const formData = new FormData();
    formData.append('audio', file);
    formData.append('location', audioURL);
    try {
      const response = await fetch('/api/manager', {
        method: 'POST',
        body: formData
      });
      let transcript = '';
      if (!response.ok) {
        transcript = 'Hmm, something went wrong. Please try again later.';
      } else {
        const result = await response.json();
        if (result?.transcript.length) {
          transcript = result?.transcript;
        } else {
          transcript = 'Hmm, I couldn\'t understand what. Please try again.';
        }
      }
      setAction('speaking');
      await getVoiceFromText(transcript);
    } catch (error) {
      const transcript = 'Hmm, something went wrong. Please try again later.';
      setAction('speaking');
      await getVoiceFromText(transcript);
    }
  }
  async function getVoiceFromText(text) {
    try {
      const response = await fetch('/api/audio', {
        method: 'POST',
        body: JSON.stringify({
          text
        })
      });
      if (response.ok) {
        const result = await response.json();
        const url = result?.url;
        setAudioUrl(url);
        setAction('speaking');
        setQueue([...queue, url]);
      }
    } catch (e) {
      setAudioUrl('https://storage.googleapis.com/productlamb_project_images/1725320037970-14-speech-1725320037970.mp3');
      setAction('speaking');
      console.error('Contact support', e);
    }
  }
  async function playAudio() {
    if (audioUrl && audioRef) {
      console.log('playing audio');
      await audioRef.play();
    }
  }
  async function removeTempAudioFiles() {
    await Promise.all(queue.map(async url => {
      const r = await fetch('/api/audio', {
        method: 'POST',
        body: JSON.stringify({
          url: audioUrl
        })
      });
      return r;
    }));
  }
  useEffect(() => {
    playAudio();
  }, [audioUrl && audioRef]);
  useEffect(() => {
    if (!open) {
      if (isRecording) {
        stopRecording();
        setVolumes([]);
      }
      if (audioURL) {
        URL.revokeObjectURL(audioURL);
        setAudioURL(null);
      }
      if (blob) {
        setBlob(null);
      }
      setAction("");
      setRequestMade(false);
      setAudioURL(null);
      removeTempAudioFiles();
    } else {
      startRecording();
    }
  }, [open]);
  return <div className='h-72 flex flex-col items-center justify-center text-black dark:text-neutral-200'> 
      {isRecording ? <p className={'text-center ' + (avgVolume <= 30 ? '' : ' invisible')}>Please try to speak up!</p> : null}
      {isRecording ? <div className='flex flex-row justify-evenly items-end h-48 w-5/6 border-2 gap-2 border-neutral-500 dark:border-neutral-300'>
          {volumes.map((volume, i) => <div key={i} style={{
        height: `${volume / 2}px`,
        backgroundColor: 'orange'
      }} className='inline-block w-7' />)}
        </div> : <div className='flex flex-row px-3 items-center h-48 w-5/6 justify-center border-2 border-neutral-500 dark:border-neutral-300'>
          {!audioUrl ? <BarLoader color="#F28C28" /> : <BeatLoader color="#F28C28" size={18} />}
        </div>}
      <audio ref={ref => setAudioRef(ref)} src={audioUrl ?? undefined} style={{
      display: 'none'
    }} // Hide the audio element
    onEnded={() => {
      setAudioUrl(null);
      if (action === 'speaking') {
        setOpen(false);
      }
      setAction("");
    }} />
    </div>;
}
_s(AudioRecorder, "7CEfD07Trr1BviZPXlz7JKdyoik=");
_c = AudioRecorder;
;
export function PLManagerRequestModal({
  open,
  setOpen
}) {
  const title = 'What can I help you with?';
  return <PLBaseModal open={open} title={title} setOpen={setOpen} size='sm'>
      <AudioRecorder open={open} setOpen={setOpen} />
    </PLBaseModal>;
}
_c2 = PLManagerRequestModal;
var _c, _c2;
$RefreshReg$(_c, "AudioRecorder");
$RefreshReg$(_c2, "PLManagerRequestModal");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;