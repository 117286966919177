// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/layouts/root-layout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/layouts/root-layout.tsx");
  import.meta.hot.lastModified = "1726706773000";
}
// REMIX HMR END

import { SignedIn, SignedOut } from "@clerk/remix";
import { AuthenticatedLayout } from "./authenticated-layout";
import { Outlet } from "@remix-run/react";
import { SidebarProvider } from "~/backend/providers/siderbar";
import { AdminProvider } from "~/backend/providers/admin";
export function RootLayout({
  appData,
  setupIsComplete,
  toggleDarkMode,
  darkMode,
  isInternal
}) {
  return <SidebarProvider>
      <AdminProvider>
        <div>
          <SignedIn>
            <AuthenticatedLayout appData={appData} setupIsComplete={setupIsComplete} toggleDarkMode={toggleDarkMode} darkMode={darkMode} isInternal={isInternal} />
          </SignedIn>
          <SignedOut>
            <div>
              <Outlet />
            </div>
          </SignedOut>
        </div>
      </AdminProvider>
    </SidebarProvider>;
}
_c = RootLayout;
var _c;
$RefreshReg$(_c, "RootLayout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;